import React from 'react';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';
import Layout from '../layout/Layout';

import PostListing from '../components/PostListing/PostListing';
import { siteTitle } from '../../data/SiteConfig';
import { usePostFields } from '../hooks/usePostFields';

const CategoryTemplate = props => {
  const postFields = usePostFields(props.data.posts.edges);

  return (
    <Layout>
      <Helmet title={`Notes" | ${siteTitle}`} />
      <h1>Notes</h1>
      <p>
        These are candid thoughts. In contract to articles which are polished
        posts, these part of my digital garden where I publish anything I'd like
        to say to the world.
      </p>
      <hr />
      <PostListing postList={postFields} isBig />
    </Layout>
  );
};

export default CategoryTemplate;

/* eslint no-undef: "off" */
export const pageQuery = graphql`
  query NotesPage($category: String) {
    posts: allMarkdownRemark(
      limit: 1000
      sort: { fields: [fields___date], order: DESC }
      filter: {
        fileAbsolutePath: { regex: "/(notes)/.*\\\\.md$/" }
        frontmatter: { categories: { eq: $category } }
      }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
            date: fancyDate
          }
          excerpt
          timeToRead
          frontmatter {
            title
            tags
            cover {
              childImageSharp {
                fluid(maxWidth: 650, maxHeight: 250, quality: 60) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            date
          }
        }
      }
    }
  }
`;
